<template>
  <div class="receive-address">
    <van-nav-bar :title="pageTitle" left-arrow @click-left="goBack" :border="false" />
    <van-pull-refresh v-model="refreshing" @refresh="refreshData">
      <div class="receive-address-list">
        <van-row gutter="10">
          <van-col v-for="(pageItem,index) in configFunction" span="24" :key="pageItem.name">
            <div class="page-item" :style="`background-color: ${pageItem.color};`">
              <van-image class="page-icon" :src="pageItem.iconUrl" />
              <p class="page-name">{{pageItem.name}}</p>
              <van-count-down 
                v-if="pageItem.autoTakingVO && pageItem.autoTakingVO.expire"
                class="count-down" 
                :time="pageItem.autoTakingVO.expire * 1000"
                @finish="fetchData"
              />
            </div>
            <div class="btn-bar">
              <template v-if="pageItem.autoTakingVO">
                <a class="bar-btn" v-if="pageItem.autoTakingVO.status === 0" @click="turnOnAuto(pageItem)">{{$t('button.turnOnAuto')}}</a>
                <a class="bar-btn" v-else @click="turnOffAuto(pageItem)">{{$t('button.turnOffAuto')}}</a>
              </template>
              <a class="bar-btn" @click="goToSellerAccount(pageItem)">{{$t('title.detail')}}</a>
            </div>
          </van-col>
        </van-row>
      </div>
    </van-pull-refresh>
  </div>
</template>

<script>
import { ref } from 'vue';
import { useRouter } from 'vue-router';
import i18n from '@/assets/lang/index';
import { querySellerAccountInfo, enableAutoTaking, disableAutoTaking } from "@/api";

export default {
  setup() {
    const router = useRouter();
    const goBack = () => router.back();
    
    const refreshing = ref(false);
    const cellThemeVars = {
      cellVerticalPadding: '13px',
    };
    return {
      goBack,
      refreshing,
      cellThemeVars
    };
  },
  
  data() {
    return {
      pageTitle: localStorage.getItem('pageName'),
      configFunction: [],
    };
  },
  
  mounted() {
    this.fetchData();
  },
  
  methods: {
    fetchData() {
      if (this.refreshing) {
        this.refreshing = false;
      }
      querySellerAccountInfo().then(res => {
        this.configFunction = res.data.accountConfigVOList;
        this.mode = res.data.model;
      });
    },
    refreshData() {
      this.fetchData();
    },
    goToSellerAccount(item) {
      localStorage.setItem('receiveAddress', JSON.stringify(item));
      this.$router.push('sellerAccount');
    },
    turnOnAuto(item) {
      enableAutoTaking({ type: item.type }).then(res => {
        this.fetchData();
      });
    },
    turnOffAuto(item) {
      disableAutoTaking({ type: item.type }).then(res => {
        this.fetchData();
      });
    },
  },
};
</script>

<style lang="less">
.receive-address {
  padding-bottom: 50px;
  .user-info-wrap {
    line-height: 26px;
    padding: 20px 10px;
    color: #fff;
    background: var(--van-primary-color);
    .user-header-title {
      font-size: 22px;
      font-weight: 600;
    }
  }
  .receive-address-list{
    padding: 12px;
    .page-item {
      display: flex;
      line-height: 28px;
      padding: 12px 10px;
      margin-top: 10px;
      font-size: 14px;
      background-color: #fff;
      border-radius: 8px 8px 0 0;
    }
    .page-icon,
    .page-icon img{
      width: 28px;
      height: 28px;
    }
    .page-name {
      margin-left: 10px;
      flex-grow: 1;
    }
    .page-data {
      width: 90px;
      text-align: right;
    }
    .arrow-icon {
      display: block;
      margin-left: 10px;
      line-height: 28px;
      font-size: var(--van-cell-icon-size);
      color: var(--van-cell-right-icon-color);
    }
    .count-down {
      color: #FF4060;
    }
    .btn-bar {
      display: flex;
      align-items: center;
      height: 44px;
      background-color: var(--van-primary-color);
      border-radius: 0 0 12px 12px;
      .bar-btn {
        flex: 1;
        font-size: 15px;
        color: #fff;
        text-align: center;
      }
    } 
  }
}
</style>
